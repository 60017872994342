<template>
  <div class="option-out-div survial-col nsclc">
    <div class="option-inside option-inside-false"
      @click="openQuestionaire"
      >
      {{ maxStepItem.step_code ? '已完成' + maxStepItem.step_display_name : maxStepItem.step_display_name }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    },
    stepList: {
      default: () => [],
      type: Array
    },
    type: {
      default: '',
      type: String
    },
    field: {
      default: '',
      type: String
    }
  },
  computed: {
    recordList() {
      const stepItem = this.stepList.filter(item => {
        return ['CTPL00020080'].indexOf(item.pipeline_code) !== -1
      })
      return stepItem
    },
    maxStepItem() {
      const finishSteps = this.recordList.filter(item => {
        return item.step_status === '30'
      })
      finishSteps.sort((a, b) => {
        return a.step_code - b.step_code
      })
      if (finishSteps.length === 0) {
        return {
          step_code: '',
          step_display_name: '未开始',
        }
      } else {
        return finishSteps[finishSteps.length - 1]
      }
    }
  },
  methods: {
    openQuestionaire() {
      // 未开始则定位到S1
      // 已完成S1，则定位到S2。已完成S2则定位到S3，依次类推。
      // 已完成S12则定位到S12
      let targetItem = {}
      if (this.maxStepItem.step_code) {
        const maxIndex = this.recordList.findIndex(item => {
          return item.step_code === this.maxStepItem.step_code
        })
        if (maxIndex < 11 ) {
          targetItem = this.recordList[maxIndex + 1]
        } else {
          targetItem = this.recordList[this.recordList.length - 1]
        }
      } else {
        targetItem = this.recordList.find(item => {
          return item.step_display_name === 'S1'
        })
      }
      this.$emit('openQuestionaire', this.row, targetItem)
    }
  }
}
</script>
<style lang="scss" scoped>
.chemotherapy-way {
  max-width: 100%;
  flex: 1;
  div {
    flex: 1;
  }
  .circle-flag {
    align-items: center;
    border: 1px solid #333;
    border-radius: 50%;
    display: flex;
    height: 8px;
    margin-right: 3px;
    justify-content: center;
    width: 8px;
    &.green {
      border-color: #50b240;
    }
  }
  .icon-submit {
    margin-right: 3px;
  }
  .not-allow {
    cursor: not-allowed;
    color: #d4d4d4;
    border-color: #d4d4d4;
    .circle-flag {
      border: 1px solid #d4d4d4;
    }
  }
  .add-btn {
    border: 1px solid #d4d4d4;
  }
}
.chemotherapy-way .el-select {
  border-right: 0px;
  height: 24px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  flex: 0 0 130px;
  width: 130px;
  padding: 0;
  
  .el-input {
    padding: 0;
    border-radius: 0;
    width: 100%;
  }
  .el-input__inner {
    height: 24px;
    font-size: 12px;
    padding: 0 6px;
    border: none;
    background: none;
    line-height: 24px;
    &::placeholder {
      color: red;
    }
  }
  .el-input__icon {
    line-height: 24px;
  }
  .el-input__suffix {
    right: 0;
  }
  &.option-inside-true .el-input__icon {
    color: #50b240;
  }
}
.stop-check {
  background: #34CC99;
  opacity: 0.7;
  color: #fff;
  position: absolute;
  right: 35px;
  top: -2px;
  height: 14px;
  width: 54px;
  line-height: 14px;
}
.negative-blank {
  flex: 1;
  background: #f1f1f1;
  color: #adadad;
  text-align: center;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
}
</style>
<style lang="scss">
.chemotherapy-way .el-select.option-inside-false {
  .el-input__inner {
    &::placeholder {
      color: #555;
    }
  }
  .el-input.is-disabled {
    .el-input__inner::placeholder {
      color: #d4d4d4;
    }
  }
}
</style>