<template>
  <div class="patient-list-container">
    <el-form :inline="true" class="my-row" :model="searchForm" size="mini">
      <div>
        <el-form-item label="受试者姓名">
          <el-input v-model="searchForm.name" placeholder="请输入" clearable style="width: 90px" />
        </el-form-item>
        <el-form-item label="受试者编号">
          <el-input v-model="searchForm.external_patient_code" placeholder="请输入" clearable style="width: 130px" />
        </el-form-item>
        <el-form-item v-if="pageType == '3'" label="初始子分组">
          <el-select v-model="searchForm.group_type_init" placeholder="请选择" size="mini" style="width: 140px" clearable>
            <el-option
              v-for="ii in MRDGroup"
              :key="ii.code"
              :label="ii.label"
              :value="ii.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-on:click="onSearch">
            查找
          </el-button>
        </el-form-item>
        <el-form-item style="margin-right: 0">
          <el-button v-on:click="onReset">
            重置
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      id="patientTable"
      ref="patientTable"
      :data="dtDatum"
      stripe
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      height="calc(100vh - 160px)"
      style="width: 100%"
      class="patient-table"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" />
      <el-table-column show-overflow-tooltip prop="name" label="受试者姓名" min-width="90px" fixed="left">
        <template slot-scope="scope">
          <el-tooltip :disabled="scope.row.process_status !== '2'" effect="dark" content="该受试者已经被结束研究" placement="top">
            <span :class="{'patient-name': true, 'grey': scope.row.process_status === '2'}"
              @click="viewUserInfo(scope.row)">
              {{ scope.row.name }}
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="external_patient_code" label="受试者编号" min-width="130px" fixed="left">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.process_status === '2'"
            class="item"
            effect="dark"
            content="该受试者已经被结束研究" placement="top">
            <span :style="{'color': scope.row.process_status === '2' ? '#999' : ''}">{{ scope.row.external_patient_code }}</span>
          </el-tooltip>
          <span v-else>
            {{ scope.row.external_patient_code }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="column in tableColumns"
        :label="column.label"
        :prop="column.prop"
        :key="column.prop"
        :min-width="column.minWidth"
        :width="column.width"
      >
        <template v-if="['group_type_init_display_name'].includes(column.prop)" v-slot="scope">
          {{ scope.row[column.prop] || '无' }}
        </template>
        <template v-else-if="['screen_enrollment_status'].includes(column.prop)" v-slot="scope">
          <screen-enrollment-col
            :stepList="scope.row.step_list"
            :row="scope.row"
            :key="scope.row.patient_id"
            @openQuestionaire="openCommonDialog"
            @openMRD="openMRD"
          />
        </template>
        <template v-else-if="'questionnaire-state' == column.prop" v-slot="scope">
          <step-col
            :row="scope.row"
            :stepList="scope.row.step_list"
            @openQuestionaire="openCommonDialog"
            @openMRD="openMRD"
            :key="scope.row.patient_id"
            :type="column.type"
          />
        </template>
        <template v-else-if="'treatment-way-record' == column.prop" v-slot="scope">
          <gene-col
            :row="scope.row"
            :type="column.type"
            :stepList="scope.row.step_list"
            :key="scope.row.patient_id"
            @openQuestionaire="openCommonDialog"
            @openMRD="openMRD"
          />
        </template>
        <template v-else-if="'mrd-visit-date' == column.prop" v-slot="scope">
          <visit-col
            :row="scope.row"
            type="standard"
            :stepList="scope.row.step_list"
            @openQuestionaire="openCommonDialog"
            @openMRD="openMRD"
            :key="scope.row.patient_id"
          />
        </template>
        <template v-else-if="'survial-follow-up' == column.prop" v-slot="scope">
          <survial-col
            :row="scope.row"
            :stepList="scope.row.step_list"
            @openQuestionaire="openCommonDialog"
            :key="scope.row.patient_id"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="75px" fixed="right">
        <template slot-scope="scope">
          <el-dropdown placement="bottom" trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-more" style="cursor: pointer;color: #0079fe"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="genetrials-common-dropdown">
              <el-dropdown-item @click.native="viewUserInfo(scope.row)">查看</el-dropdown-item>
              <template v-if="!disableNSCLCOperation">
                <el-dropdown-item @click.native="handleActionRemind('1', scope.row)">编辑</el-dropdown-item>
                <el-dropdown-item @click.native="handleDelete(scope.row)">删除</el-dropdown-item>
                <el-dropdown-item v-if="scope.row.group_type !== '90'" @click.native="handleActionRemind('3', scope.row)">
                  {{ scope.row.process_status === '2' ? '继续研究' : '结束研究'}}
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.group_type !== '90'" @click.native="viewIncidentInfo(scope.row.patient_id)">上报不良事件</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-on:size-change="onPageSizeChange" v-on:current-change="onPageIdxChange"
      :current-page="pagination.page" :page-sizes="[20, 50, 100]" :page-size="pagination.size" align="right"
      layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>
    <dialog-patient-add
      v-if="modalAdd.isVisible"
      :dialogVisible="modalAdd.isVisible"
      :actionType="modalAdd.actionType"
      :fromPatientId="modalAdd.fromPatientId"
      @closeDialog="modalAdd.isVisible=false"
      @refresh="refresh"
    />
    <dialog-notice
      v-if="showNotice"
      :dialogVisible="showNotice"
      :row="currentRow"
      @closeDialog="showNotice = false"
      @refresh="refresh"
    />
    <dialog-incident
      v-if="showIncident"
      hospitalName="广东省人民医院"
      msg="系统将会上报不良事件给该受试者所属研究中心的研究人员。"
      :dialogVisible="showIncident"
      :patient-id="currentRow.patient_id"
      @closeDialog="showIncident = false"
    />
    <!-- 编辑受试者、删除受试者、继续/结束研究的动作提示 -->
    <dialog-action-remind
      v-if="actionRemindVisible"
      :dialogVisible="actionRemindVisible"
      :actionRemindInfo="actionRemindInfo"
      @close="handleActionRemindClose"
      @confirm="handleActionRemindConfirm"
    />
    <dialog-commom-q
      v-if="showCommonQ"
      :dialogVisible="showCommonQ"
      :currentPatientId="currentRow.patient_id"
      :targetStep="targetStep"
      :targetTask="targetTask"
      @closeDialog="showCommonQ = false; refresh()"
    />
    <dialog-delete
      v-if="showDelete"
      :dialogVisible="showDelete"
      :actionRemindInfo="actionRemindInfo"
      @close="showDelete = false"
      @confirm="handleDeleteConfirm"
    />
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import store from "@/store/index.js";
import dayjs from 'dayjs';
import defaultResponseNotify from "@/helpers/message.js";
import DialogNotice from '@/components/dialog/DialogNotice'
import DialogIncident from '@/components/dialog/DialogIncident'
import DialogPatientAdd from '@/views/NSCLC/Dialog/DialogPatientAdd'
import { modifyProcess, getGroupUserList } from '@/apis/user'
import StepCol from './StepCol.vue';
import GeneCol from './GeneCol.vue';
import VisitCol from './VisitCol.vue';
import ActionRemindMixin from '@/mixins/ActionRemindMixin'
import ScreenEnrollmentCol from './ScreenEnrollmentCol'
import DialogCommomQ from '../Dialog/DialogCommonQ.vue'
import DialogDelete from '../Dialog/DialogDelete'
import SurvialCol from './SurvialCol.vue';

const urlMap = {
  '/CTPJ00000050/index': '0',
  '/CTPJ00000050/standard': '100',
  '/CTPJ00000050/MRD': '200',
  '/CTPJ00000050/disenroll': '900'
}
export default {
  name: "PatientList",
  mixins: [ActionRemindMixin],
  components: {
    DialogNotice,
    StepCol,
    GeneCol,
    VisitCol,
    DialogIncident,
    DialogPatientAdd,
    ScreenEnrollmentCol,
    DialogCommomQ,
    DialogDelete,
    SurvialCol
  },
  props: {
    pageType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      page_indexOrDetail: "",
      // 分页属性
      pagination: {
        size: 20,
        page: 1,
        total: 0,
      },
      loading: false,
      dtHeight: "100",
      dtDatum: [],
      // 搜索框
      searchForm: {
        name: "",
        external_patient_code: "",
        group_type_init: ''
      },
      // 模态框 add
      modalAdd: {
        isVisible: false,
        actionType: "1",
        patientInfo: {},
      },
      // 模态框 修改分组
      modalChangeGroup: {
        isVisible: false,
        patientId: "",
      },
      showQuestionaire: false,
      currentRow: {},
      currentPage: '',
      showNotice: false,
      initCode: '',
      showIncident: false,
      multipleSelection: [],
      groupInfo: {
        '100': {
          label: '标准治疗组',
          url: '/CTPJ00000050/standard'
        },
        '200': {
          label: 'MRD指导治疗组',
          url: '/CTPJ00000050/MRD'
        },
        '210': {
          label: 'MRD指导治疗组',
          url: '/CTPJ00000050/MRD'
        },
        '220': {
          label: 'MRD指导治疗组',
          url: '/CTPJ00000050/MRD'
        },
        '900': {
          label: '未入组'
        }
      },
      MRDGroup: [{
        code: '',
        label: '--'
      },{
        code: '200',
        label: '无'
      },{
        code: '220',
        label: '阳性组'
      },{
        code: '210',
        label: '阴性组'
      }],
      showCommonQ: false,
      targetStep: '',
      targetTask: '',
      showDelete: false
    };
  },
  computed: {
    tableColumns: {
      get() {
        let columns = []
        if (this.pageType == '2') {
          // 标准治疗组
          columns.push(
            {
              label: '筛选期          P1              入组期',
              prop: 'screen_enrollment_status',
              minWidth: '230px',
              width: ''
            },
            {
              label: 'P2',
              prop: 'questionnaire-state',
              minWidth: '',
              width: '80px',
              type: 'p1p2'
            },{
              label: '驱动基因                    初次治疗',
              prop: 'treatment-way-record',
              minWidth: '230px',
              width: '',
            },{
              label: '术后访视（第*次）| MRD状态',
              prop: 'mrd-visit-date',
              minWidth: '1220px',
              width: '',
              type: ''
            },{
              label: '生存随访',
              prop: 'survial-follow-up',
              minWidth: '80px',
              width: '',
              type: ''
            }
          )
        }
        if (this.pageType == '3') {
          // MRD指导治疗组
          columns.push(
            {
              label: '筛选期          P1              入组期',
              prop: 'screen_enrollment_status',
              minWidth: '230px',
              width: ''
            },
            {
              label: 'P2',
              prop: 'questionnaire-state',
              minWidth: '',
              width: '80px',
              type: 'p1p2'
            },{
              label: '初始子分组',
              prop: 'group_type_init_display_name',
              minWidth: '90px',
              width: '',
            },{
              label: '驱动基因                         初次治疗',
              prop: 'treatment-way-record',
              minWidth: '230px',
              width: '',
            },{
              label: '术后访视（第*次）| MRD状态',
              prop: 'mrd-visit-date',
              minWidth: '1220px',
              width: '',
              type: ''
            },{
              label: '生存随访',
              prop: 'survial-follow-up',
              minWidth: '80px',
              width: '',
              type: ''
            }
          )
        }
        return columns
      }
    },
    disableNSCLCOperation() {
      return store.state.user.disableNSCLCOperation
    }
  },
  watch: {
    $route(to, from) {
      // 每个页面，筛选条件不一样
      this.getCurrentPage()
      this.searchForm.external_patient_code = ''
      this.$nextTick(() => {
        this.$refs.patientTable.doLayout()
      })
      this.reloadPage();
    },
  },
  mounted() {
    // 载入dt
    this.getCurrentPage()
    this.reloadPage();
    // resize
    this.$nextTick(() => {
      this.resizeDt();
      window.onresize = () => {
        this.resizeDt();
      };
    });
  },
  methods: {
    // 分页方法
    onPageSizeChange(val) {
      this.pagination.size = val;
      this.goPage1ReloadDt();
    },
    onPageIdxChange(val) {
      console.log("page idx change");
      this.pagination.page = val;
      this.getList();
    },
    reloadPage() {
      this.goPage1ReloadDt(true);
    },
    goPage1ReloadDt(isInit) {
      this.pagination.page = 1;
      this.getList(isInit);
    },
    getList(isInit) {
      this.loading = true
      let external_patient_code = ''
      if (isInit && this.initCode) {
        external_patient_code = this.initCode
        this.searchForm.external_patient_code = this.initCode
      } else {
        external_patient_code = this.searchForm.external_patient_code
      }

      // 0所有受试者列表 
      const apiUrl = getGroupUserList

      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        group_type: this.currentPage == '0' ? '' : this.searchForm.group_type ? this.searchForm.group_type : this.currentPage,
        group_type_init: this.currentPage == '0' ? '' : this.searchForm.group_type_init ? this.searchForm.group_type_init : '',
        name: this.searchForm.name ? this.searchForm.name.trim() : '',
        external_patient_code: external_patient_code ? external_patient_code.trim() : '',
        institute_id: this.searchForm.institute_id || undefined,
        principal_id: this.searchForm.principal_id || undefined
      }
      apiUrl(params).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.dtDatum = res.entities.map(item => {
            return {
              ...item,
              create_datetime: item.create_datetime ? dayjs(item.create_datetime).format('YYYY-MM-DD') : ''
            }
          })
          this.dtDatum.map(item => {
            item.step_list.sort((a, b) => {
              // 提取字符串中的数字部分
              const numA = parseInt(a.step_code.match(/\d+/)[0], 10);
              const numB = parseInt(b.step_code.match(/\d+/)[0], 10);
  
              // 从大到小排序
              return numA - numB;
            })
          })
          this.pagination.total = res.total
        }
      })
    },
    getCurrentZoomLevel() {
      const screenWidth = window.screen.width;
      const windowWidth = window.innerWidth;
      return windowWidth / screenWidth;
    },
    resizeDt() {
      const zoomLevel = this.getCurrentZoomLevel();
      // 假设150%缩放大约等于0.67（这个值可能需要根据实际情况调整）
      let zoomAdjustment;
      if (zoomLevel <= 0.67) {
          zoomAdjustment = this.pageType === '3' ? 550 : 510; // 150%缩放
      } else if (zoomLevel <= 0.8) {
          zoomAdjustment = 390; // 假设125%缩放时的调整值
      } else if (zoomLevel < 1) {
          zoomAdjustment = 290; // 假设110%缩放时的调整值
      } else {
          zoomAdjustment = 160; // 100%缩放
      }
      this.dtHeight = document.documentElement.clientHeight / zoomLevel - zoomAdjustment;
      const tb = document.querySelector("#patientTable .el-table__body-wrapper");
      tb.style.height = `${this.dtHeight}px`;
      tb.style.overflowY = `auto`;
    },
    // 查询
    onSearch() {
      this.goPage1ReloadDt();
    },
    onReset() {
      this.searchForm = {
        name: "",
        external_patient_code: "",
        institute_id: "",
        principal_id: ""
      }
      this.onSearch();
    },
    // 添加患者信息
    onAddPatient() {
      this.modalAdd.title = "添加";
      this.modalAdd.actionType = "1";
      this.modalAdd.isVisible = true;
    },
    onSavedPatient() {
      this.modalChangeGroup.isVisible = false;
      this.modalAdd.isVisible = false;
      this.getList();
    },
    // 修改患者信息
    onEditPatient(patientId) {
      this.modalAdd.title = "编辑";
      this.modalAdd.actionType = "3";
      this.modalAdd.isVisible = true;
      this.modalAdd.fromPatientId = patientId
    },
    onDeletePatient(patientId) {
      var _thisVue = this;
      const payload = {
        patient_id: patientId,
      };
      store.dispatch("deletePatient", payload).then((response) => {
        defaultResponseNotify(response);
        _thisVue.getList();
        store.dispatch("getPatientCount");
      });
    },
    // 修改患者分组
    onChangePatientGroup(row) {
      this.modalChangeGroup.isVisible = true;
      this.currentRow = row
    },
    getCurrentPage() {
      this.currentPage = urlMap[this.$route.path]
      this.initCode = this.$route.query?.external_patient_code
    },
    refresh() {
      this.onSearch()
    },
    openNoticeDialog(row) {
      this.currentRow = row
      this.showNotice = true
    },
    endAll(row) {
      const params = {
        patient_id: row.patient_id,
        process_status: row.process_status === '1' ? 2 : 1
      }
      modifyProcess(params).then(res => {
        if (res.code === 200) {
          defaultResponseNotify(res);
          this.onSearch()
        }
      })
    },
    viewUserInfo(row) {
      this.modalAdd.title = "查看";
      this.modalAdd.actionType = "2";
      this.modalAdd.isVisible = true;
      this.modalAdd.fromPatientId = row.patient_id
    },
    jumpDetail(group_type, external_patient_code) {
      let url = this.groupInfo[group_type].url
      if (external_patient_code) {
        // 点击当前进度
        // 新开界面
        if (url) {
          window.open(`${url}?external_patient_code=${external_patient_code}&timestamp=${Date.now()}`, '_blank')
        }        
      } else {
        // 点击所属分组
        // 无patient_code
        this.$router.push({
          path: url,
          query: {
            timestamp: Date.now()
          },
        });
      }
    },
    viewIncidentInfo(patient_id) {
      this.currentRow.patient_id = patient_id
      this.showIncident = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    openCommonDialog(row, item) {
      this.currentRow = row
      this.targetStep = item.step_code
      this.targetTask = null
      this.showCommonQ = true
    },
    openMRD(row, stepCode, mrd) {
      this.currentRow = row
      this.targetStep = stepCode
      this.targetTask = mrd
      this.showCommonQ = true
    },
    handleDelete(row) {
      this.actionRemindInfo = {
        patient_id: row.patient_id,
        process_status: row.process_status,
        committed_questionnaire: row.committed_questionnaire
      }
      this.showDelete = true
    },
    handleDeleteConfirm() {
      this.onDeletePatient(this.actionRemindInfo.patient_id)
      this.showDelete = false
    }
  }
 
};
</script>

<style lang="scss" scoped>
.patient-list-container {
  background: #fff;
  // margin: 10px;
  // border-radius: 8px;
  padding: 10px;
  .patient-name {
    color: #0079fe;
    cursor: pointer;
    &.grey {
      color: #999;
      cursor: default;
    }
  }
  .project-name {
    display: inline-block;
    &.can-click {
      color: #0079fe;
      cursor: pointer;
    }
  }
}

.patient-list-container .my-row {
  padding: 0;
}

.patient-list-container .text-btn-in-dg {
  margin-right: 10px;
}

.patient-list-container .my-link:hover {
  cursor: pointer;
}

.option-out-div div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 24px;
  border-right: none;
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  cursor: pointer;
  img {
    margin-right: 3px;
  }
}

.chemotherapy-way {
  display: flex;
}

.chemotherapy-way .el-select {
  border-right: 0px;
  height: 24px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  flex: 0 0 89px;
  width: 89px;
  padding: 0;
  .el-input {
    padding: 0;
    border-radius: 0;
    width: 100%;
  }
  .el-input__inner {
    height: 24px;
    font-size: 12px;
    padding: 0 6px;
    border: none;
    background: none;
    line-height: 24px;
  }
  .el-input__icon {
    line-height: 24px;
  }
  .el-input__suffix {
    right: 0;
  }
  &.option-inside-true .el-input__icon {
    color: #50b240;
  }
}
.option-inside-false.blank {
  width: 240px;
}
.second-group {
  border: 1px solid #d4d4d4;
  color: #333333;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  img {
    margin-right: 5px;
  }
  &.success {
    border: 1px solid #50b240;
    color: #50b240;
  }
}
.option-content {
  margin-right: -1px;
}
.option-inside-true {
  border: 1px solid #ddd;
  color: #50b240;
  .el-input__inner {
    color: #50b240;
  }
  .el-icon-circle-plus {
    color: #F6CAAA;
    margin-left: 3px;
  }
  .el-icon-remove {
    color: #A0D797;
    margin-left: 3px;
  }
}
.option-inside-expired {
  border: 1px solid #F6CAAA;
  // color: #eb8127;
}

.option-inside-false {
  border: 1px solid #d4d4d4;
  color: #333333;
  flex: 1;
}

.option-out-div div:last-child {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.option-out-div div:first-child {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.option-out-div div:last-child.option-inside-false {
  border-right: 1px solid #d4d4d4;
}

.option-out-div div:last-child.option-inside-true {
  border-right: 1px solid #50b240;
}

.option-out-div div:last-child.option-inside-expired {
  border-right: 1px solid #eb8127;
}

.option-out-div .icon-submit {
  width: 10px;
  color: #333;
}

</style>
